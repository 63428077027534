import React from "react";

const Services = () => {
  return (
    <div>
      <h1>Nos Services</h1>
      <p>Voici la liste de nos services...</p>
    </div>
  );
};

export default Services;
